import React from "react"
import { View } from "react-native"
import * as Animatable from "react-native-animatable"

interface Props {
	children: any
	style?: any
	animate?: boolean
	delay?: number
}

const FlashingWrapper = ({ animate, style, children, delay }: Props) => {
	if (animate) {
		return (
			<Animatable.View
				style={style}
				animation="flash"
				iterationCount="infinite"
				delay={delay || 8000}
				iterationDelay={2000}
			>
				{children}
			</Animatable.View>
		)
	}
	return <View style={style}>{children}</View>
}

export default FlashingWrapper
