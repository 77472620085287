import React, { useEffect, useState } from "react"
import { StyleSheet, View, Image, Text, TouchableOpacity, ImageBackground } from "react-native"
import * as Linking from "expo-linking"
import { useIsConnected } from "react-native-offline"
import PropTypes from "prop-types"

import TranslatableText from "./TranslatableText"

const styles = StyleSheet.create({
	outerContainer: {
		flex: 1,
		flexDirection: "row",
		width: "100%",
		height: "100vh",
		justifyContent: "center",
		alignItems: "center",
		position: "relative",
		zIndex: 2,
		backgroundColor: "rgb(243, 243, 244)",
	},
	makeYourOwnButton: {
		backgroundColor: "#20BB37",
		padding: 10,
		alignSelf: "flex-start",
		borderRadius: 10,
		position: "absolute",
		bottom: 10,
		left: 10,
	},
	leftContainer: {
		flexDirection: "column",
		alignSelf: "stretch",
		alignItems: "center",
		display: "flex",
		paddingTop: 30,
		position: "relative",
		zIndex: 2,
	},
	rightContainer: {
		width: 340,
		marginLeft: 40,
		flexShrink: 1,
	},
	rightInnerContainer: {
		display: "flex",
		flexDirection: "column",
	},
	logoContainer: {
		display: "flex",
		justifyContent: "flex-start",
		alignItems: "flex-start",
		marginBottom: 12,
	},
	logo: {
		width: 64,
		height: 64,
		flexShrink: 0,
		position: "relative",
		borderRadius: 20,
	},
	titleText: {
		fontSize: 40,
		fontWeight: "900",
		color: "black",
	},
	subtitleText: {
		fontWeight: "normal",
		marginTop: 20,
		marginBottom: 30,
		color: "black",
		fontSize: 20,
	},
	phoneContainer: {
		width: 380,
		height: 717,
		flex: 1,
		flexDirection: "row",
		justifyContent: "center",
	},
	appContainer: {
		width: 336,
		height: 580,
		overflow: "hidden",
		top: 58,
		backgroundColor: "#fff",
	},
})

export function DesktopAppContainer({ activeOrgData, children }) {
	const [appData, setAppData] = useState(null)
	const isConnected = useIsConnected()

	useEffect(() => {
		if (activeOrgData && activeOrgData.info) {
			const data = {
				icon: activeOrgData.info.logo_image_url,
				title: activeOrgData.info.org_name,
				subtitle: `Created by ${activeOrgData.info.org_name} using the Learn.ink platform. Open this app on your phone for the best experience`,
			}
			setAppData(data)
		} else if (activeOrgData === "not_found") {
			const data = {
				icon: "https://learnink-user-static.s3.eu-west-2.amazonaws.com/images/icon.png",
				title: "Welcome!",
				subtitle: "Get started by registering with an organisation or signing in to your account",
			}
			setAppData(data)
		} else if (activeOrgData === "access_denied") {
			const data = {
				icon: "https://learnink-user-static.s3.eu-west-2.amazonaws.com/images/icon.png",
				title: "No access",
				subtitle: "Sorry you don't have permission to access this page",
			}
			setAppData(data)
		} else {
			const data = {
				icon: "https://learnink-user-static.s3.eu-west-2.amazonaws.com/images/icon.png",
				title: "Welcome!",
				subtitle: "Get started by registering with an organisation or signing in to your account",
			}
			setAppData(data)
		}
	}, [activeOrgData])

	// Function opens in a new window if you are using the browser
	const handleLink = (url) => {
		if (window) {
			// Detects a window already open
			window.open(url, "_blank")
		} else {
			Linking.openURL(url)
		}
	}

	return (
		<View style={styles.outerContainer}>
			<View style={styles.leftContainer}>
				<ImageBackground
					style={styles.phoneContainer}
					source={require("../../assets/images/phone_outline.svg")}
					alt="phone"
				>
					<View style={styles.appContainer}>{children}</View>
				</ImageBackground>
			</View>
			<View style={styles.rightContainer}>
				<View style={styles.rightInnerContainer}>
					{appData && appData.icon ? (
						<View style={styles.logoContainer}>
							<Image style={styles.logo} source={{ uri: appData.icon }} />
						</View>
					) : null}
					{appData && appData.title ? <Text style={styles.titleText}>{appData.title}</Text> : null}
					{appData && appData.subtitle ? <Text style={styles.subtitleText}>{appData.subtitle}</Text> : null}
				</View>
			</View>
			<TouchableOpacity style={styles.makeYourOwnButton} onPress={() => handleLink("https://learn.ink")}>
				<TranslatableText style={{ color: "white", fontWeight: "600" }} id="make_your_own" textAlign="center" />
			</TouchableOpacity>
			{isConnected !== null && (
				<View
					style={{
						backgroundColor: isConnected ? "#20bb37" : "#f95151",
						right: 10,
						alignSelf: "flex-end",
						top: 10,
						padding: 10,
						borderRadius: 10,
						position: "absolute",
					}}
				>
					<Text style={{ color: "white", fontWeight: "600", textAlign: "center" }}>
						{isConnected ? "Online" : "Offline"}
					</Text>
				</View>
			)}
		</View>
	)
}

DesktopAppContainer.propTypes = {
	activeOrgData: PropTypes.shape({
		info: PropTypes.shape({
			org_name: PropTypes.string,
			logo_image_url: PropTypes.string,
			cover_image_url: PropTypes.string,
		}),
	}),
	children: PropTypes.any.isRequired,
}

DesktopAppContainer.defaultProps = {
	activeOrgData: "not_found",
}

export default DesktopAppContainer
