import React from "react"
import { Text } from "react-native"
import i18n from "i18n-js"
import PropTypes from "prop-types"

const TranslatableText = ({ style, id, textAlign, numberOfLines }) => {
	if (textAlign === "center") {
		return <Text style={[style, { textAlign }]}>{i18n.t(id)}</Text>
	} else {
		if (i18n.locale === "ar") {
			return (
				<Text
					style={[
						style,
						{
							textAlign: textAlign === "left" ? "right" : "left",
							writingDirection: "rtl",
						},
					]}
					numberOfLines={numberOfLines}
				>
					{i18n.t(id)}
				</Text>
			)
		} else {
			return (
				<Text style={[style, { textAlign }]} numberOfLines={numberOfLines}>
					{i18n.t(id)}
				</Text>
			)
		}
	}
}

TranslatableText.propTypes = {
	style: PropTypes.instanceOf(Object),
	id: PropTypes.string.isRequired,
	textAlign: PropTypes.string,
	numberOfLines: PropTypes.number,
}

TranslatableText.defaultProps = {
	style: {},
	textAlign: "left",
	numberOfLines: 10,
}

export default TranslatableText
