// This function is used alongside loadable components
// It will try to reload the component 5 times before eventually giving up
export default function loaderRetry(fn, retriesLeft = 5, interval = 1000) {
	return new Promise((resolve, reject) => {
		fn()
			.then(resolve)
			.catch((error) => {
				setTimeout(() => {
					if (retriesLeft === 1) {
						reject(error)
						return
					}
					loaderRetry(fn, retriesLeft - 1, interval).then(resolve, reject)
				}, interval)
			})
	})
}
