import React, { useState } from "react"
import { Platform, useWindowDimensions, View } from "react-native"
import { StatusBar } from "expo-status-bar"
import Constants from "expo-constants"
import { NetworkProvider } from "react-native-offline"
import AsyncStorage from "@react-native-async-storage/async-storage"
import i18n from "i18n-js"
import PropTypes from "prop-types"

import { ScaleContext, OrgThemeContext, LanguageContext } from "../../utils/context/Context"

import DesktopAppContainer from "./DesktopAppContainer"
import LoadingSpinner from "../common/loading/LoadingSpinner"
import SaveOfflineWrapper from "../offline/SaveOfflineWrapper"
import AudioContainer from "../audio/Audio"

const pingUrl = `${Constants.expoConfig.extra.userApi}users/ping`

const defaultTheme = {
	primary: "rgb(255, 193, 7)",
	secondary: "rgb(43, 135, 201)",
	courseBackground: "#acd2ea",
	logoImgUrl: "https://learnink-user-static.s3.eu-west-2.amazonaws.com/images/org_logo_default.png",
}

export function AppContainer({ activeOrgData, children }) {
	const [pageState, setPageState] = useState(null)
	const windowWidth = useWindowDimensions().width
	const windowHeight = useWindowDimensions().height

	const desktopView = !!(Platform.OS === "web" && windowWidth > 500)
	const width = desktopView === true ? 336 : windowWidth
	const height = desktopView === true ? 580 : windowHeight
	const scale = width / 320
	const scaleData = {
		scale,
		width,
		height,
		desktopView,
	}

	const changeDefaultLanguage = async (languageCode) => {
		i18n.locale = languageCode
		await AsyncStorage.setItem("language", languageCode)
		setPageState("loading")
		setTimeout(() => {
			setPageState(null)
		}, 600)
	}

	if (pageState === "loading") {
		return <LoadingSpinner size="large" color="dodgerblue" />
	}

	return (
		<NetworkProvider pingServerUrl={pingUrl}>
			<SaveOfflineWrapper>
				<AudioContainer>
					<ScaleContext.Provider value={scaleData}>
						<LanguageContext.Provider value={changeDefaultLanguage}>
							<OrgThemeContext.Provider
								value={
									activeOrgData && activeOrgData.info
										? { ...activeOrgData.info.theme, logoImgUrl: activeOrgData.info.logo_image_url }
										: defaultTheme
								}
							>
								{desktopView === true ? (
									<DesktopAppContainer activeOrgData={activeOrgData}>{children}</DesktopAppContainer>
								) : (
									<>
										{Platform.OS === "android" && (
											<StatusBar backgroundColor="black" style="light" />
										)}
										<View
											style={{
												width,
												height: Platform.OS === "android" ? "100%" : height,
												overflow: "hidden",
												paddingTop: Constants.statusBarHeight,
											}}
										>
											{children}
										</View>
									</>
								)}
							</OrgThemeContext.Provider>
						</LanguageContext.Provider>
					</ScaleContext.Provider>
				</AudioContainer>
			</SaveOfflineWrapper>
		</NetworkProvider>
	)
}

AppContainer.propTypes = {
	activeOrgData: PropTypes.any.isRequired,
	children: PropTypes.any.isRequired,
}

export default AppContainer
