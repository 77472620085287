import Constants from "expo-constants"
import { Platform } from "react-native"
import i18n from "i18n-js"
import { timezone } from "expo-localization" // FIX THIS

import { validateCourseContent, validateUnauthedUserData } from "./validators"

import { firebaseAuth } from "./firebaseAuthenticate"

const userAuthedApiVersion = "2.0"

const getUser = async () => {
	try {
		const user = firebaseAuth.currentUser
		return user
	} catch (err) {
		return null
	}
}

export const fetchCourseContent = async (courseId, orgId) => {
	const fetch_url = `${Constants.expoConfig.extra.s3OrgContentBucketUrl}${orgId}/courses/${courseId}.json`
	var headers = new Headers()
	headers.append("cache-control", "no-cache")
	try {
		const response = await fetch(fetch_url, {
			method: "GET",
			headers,
		})
		if (response.ok) {
			const courseContent = await response.json()
			const courseContentValid = validateCourseContent(courseContent)
			if (courseContentValid) {
				courseContent.last_updated = new Date().toISOString()
				const data = {
					courseContent,
				}
				return data
			} else {
				const data = { error: "course_content_invalid" }
				return data
			}
		} else {
			const data = { error: response.status }
			return data
		}
	} catch (err) {
		console.log(err.message)
		const data = { error: err.message }
		return data
	}
}

export const fetchContent = async (orgId, contentType, id) => {
	const fetch_url = `${Constants.expoConfig.extra.s3OrgContentBucketUrl}${orgId}/${contentType}/${id}.json`
	var headers = new Headers()
	headers.append("cache-control", "no-cache")
	try {
		const response = await fetch(fetch_url, {
			method: "GET",
			headers,
		})
		if (response.ok) {
			const content = await response.json()
			if (content) {
				const data = {
					content,
				}
				return data
			} else {
				const data = { error: "content_invalid" }
				return data
			}
		} else {
			const data = { error: response.status }
			return data
		}
	} catch (err) {
		console.log(err.message)
		const data = { error: err.message }
		return data
	}
}

export const fetchAuthedUser = async (
	screenPath,
	storedOrg,
	unsyncedEvents,
	orgId,
	orgAccessCode,
	acceptTsCs,
	shareProfile,
) => {
	try {
		const user = await getUser()
		const token = await user.getIdToken()
		const versionCode =
			Platform.OS === "android" && Constants && Constants.expoConfig && Constants.expoConfig.android
				? Constants.expoConfig.android.versionCode
				: "web"
		const response = await fetch(`${Constants.expoConfig.extra.userApi}users/authed`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				screenPath,
				storedOrg,
				unsyncedEvents,
				orgId,
				orgAccessCode,
				acceptTsCs: shareProfile && shareProfile.orgId ? true : acceptTsCs,
				versionCode,
				apiVersion: userAuthedApiVersion,
				...shareProfile,
			}),
		})
		if (response.ok) {
			const authedData = await response.json()
			const data = {
				connected: true,
				authedData,
			}
			return data
		} else {
			const data = { connected: false }
			return data
		}
	} catch (err) {
		console.log(err)
		const data = { connected: false, err }
		return data
	}
}

export const registerNewUser = async (
	profileInfo,
	authState,
	org_id,
	accessCodes,
	orgAccessCode,
	screenPath,
	shareProfile,
) => {
	try {
		const language = i18n.locale
		const user = await getUser()
		const token = await user.getIdToken()
		const registrationBody = {
			user_id: user.uid,
			profileInfo,
			userDetails: {
				msisdn: authState.user.phoneNumber,
				country_code: authState.user.countryCode ? authState.user.countryCode : profileInfo.countryCode,
				registered_info: {
					platform: Platform.OS,
					browser: Constants.deviceName,
				},
				language,
				timezone,
			},
			org_id,
			accessCodes,
			orgAccessCode,
			limitedRegistrationMode: !profileInfo.ageBracket,
			screenPath: screenPath,
			apiVersion: userAuthedApiVersion,
			...shareProfile,
		}
		const response = await fetch(`${Constants.expoConfig.extra.userApi}users/register`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify(registrationBody),
		})
		if (response.ok) {
			const authedData = await response.json()
			const data = {
				authedData,
			}
			return data
		} else {
			const data = { connected: true, status: response.status }
			return data
		}
	} catch (err) {
		console.log(err)
		const data = { connected: false, err }
		return data
	}
}

export const fetchUnauthedUser = async (screenPath, storedOrg) => {
	try {
		const versionCode =
			Platform.OS === "android" && Constants && Constants.expoConfig && Constants.expoConfig.android
				? Constants.expoConfig.android.versionCode
				: "web"
		const response = await fetch(`${Constants.expoConfig.extra.userApi}users/unauthed-user`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				screenPath,
				storedOrg,
				versionCode,
			}),
		})
		if (response.ok) {
			const unauthedData = await response.json()
			const unauthedDataValid = validateUnauthedUserData(unauthedData)
			if (unauthedDataValid) {
				const data = {
					unauthedData,
				}
				return data
			} else {
				const data = { connected: true, status: "user_data_invalid" }
				return data
			}
		} else {
			const data = { connected: true, status: response.status }
			return data
		}
	} catch (err) {
		const data = { connected: false, err }
		return data
	}
}

export const registerExpoToken = async (expoToken) => {
	try {
		const user = await getUser()
		const token = await user.getIdToken()
		await fetch(`${Constants.expoConfig.extra.userApi}users/register-authed-expo-token`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				distinct_id: user.uid,
				expo_token: expoToken,
			}),
		})
	} catch (err) {
		console.log(err)
	}
}

export const checkCertificateExists = async (certificate_id) => {
	try {
		const response = await fetch(`${Constants.expoConfig.extra.s3CertificateBucketUrl}${certificate_id}.pdf`, {
			method: "HEAD",
		})
		if (response.ok) {
			return true
		} else {
			return false
		}
	} catch (err) {
		return false
	}
}

export const resolveMissingCourseCertificate = async (certificate_id, course_id, org_id) => {
	try {
		const user = await getUser()
		const token = await user.getIdToken()
		const response = await fetch(`${Constants.expoConfig.extra.userApi}events/resend-course-certificate`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				certificate_id,
				course_id,
				org_id,
			}),
		})
		if (response.ok) {
			const data = await response.json()
			return data
		} else {
			const data = null
			return data
		}
	} catch (err) {
		console.log(err)
		const data = null
		return data
	}
}

export const resolveMissingSequenceCertificate = async (sequence_id, org_id) => {
	try {
		const user = await getUser()
		const token = await user.getIdToken()
		const response = await fetch(`${Constants.expoConfig.extra.userApi}events/resend-sequence-certificate`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				sequence_id,
				org_id,
			}),
		})
		if (response.ok) {
			const data = await response.json()
			return data
		} else {
			const data = null
			return data
		}
	} catch (err) {
		console.log(err)
		const data = null
		return data
	}
}

export const enterAccessCode = async (accessCode, orgId) => {
	try {
		const user = await getUser()
		const token = await user.getIdToken()
		const response = await fetch(`${Constants.expoConfig.extra.userApi}users/${orgId}/access-code/${accessCode}`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		})
		if (response.ok) {
			const data = await response.json()
			return data
		} else {
			const data = await response.json()
			return data
		}
	} catch (err) {
		const data = { err }
		return data
	}
}

export const checkAccessCode = async (accessCode) => {
	try {
		const user = await getUser()
		const token = await user.getIdToken()
		const response = await fetch(`${Constants.expoConfig.extra.userApi}users/access-code/${accessCode}`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		})
		if (response.ok) {
			const data = await response.json()
			return data
		} else {
			const data = await response.json()
			return data
		}
	} catch (err) {
		const data = { err }
		return data
	}
}

export const registerAccessCode = async (accessCode) => {
	try {
		const user = await getUser()
		const token = await user.getIdToken()
		const response = await fetch(`${Constants.expoConfig.extra.userApi}users/register-access-code`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				user_id: user.uid,
				accessCode,
			}),
		})
		if (response.ok) {
			const data = await response.json()
			return data
		} else {
			const data = await response.json()
			return data
		}
	} catch (err) {
		const data = { err }
		return data
	}
}

export const fetchSequenceLeaderboard = async (sequence_id) => {
	try {
		const user = await getUser()
		const token = await user.getIdToken()
		const response = await fetch(`${Constants.expoConfig.extra.userApi}analytics/leaderboard/${sequence_id}`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		})
		if (response.ok) {
			const data = await response.json()
			return data
		} else {
			return null
		}
	} catch (err) {
		return null
	}
}

export const fetchLeaderboard = async (orgId, version) => {
	try {
		const user = await getUser()
		const token = await user.getIdToken()
		const response = await fetch(
			`${Constants.expoConfig.extra.userApi}analytics/${orgId}/leaderboard/v${version}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			},
		)
		if (response.ok) {
			const data = await response.json()
			return data
		} else {
			return null
		}
	} catch (err) {
		return null
	}
}

export const generateWhatsAppVerificationCode = async (msisdn) => {
	try {
		const response = await fetch(`${Constants.expoConfig.extra.userApi}users/whatsapp-verification/generate-code`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				msisdn,
			}),
		})
		if (response.ok) {
			const data = await response.json()
			return data
		} else {
			return null
		}
	} catch (err) {
		console.log(err)
		return null
	}
}

export const verifyWhatsAppVerificationCode = async (msisdn, code) => {
	try {
		const response = await fetch(`${Constants.expoConfig.extra.userApi}users/whatsapp-verification/verify-code`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				msisdn,
				code,
			}),
		})
		if (response.ok) {
			const data = await response.json()
			return data
		} else {
			return null
		}
	} catch (err) {
		console.log(err)
		return null
	}
}
