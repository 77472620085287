import React, { useEffect, useState } from "react"
import * as Linking from "expo-linking"
import loadable from "@loadable/component"
import { PostHogProvider } from "posthog-react-native"
import Constants from "expo-constants"

import loaderRetry from "./src/utils/loading/loaderRetry"
import LoadingWrapper from "./src/utils/loading/LoadingWrapper"

// eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-assignment
const AAppContainer = loadable(() => loaderRetry(() => import("./src/AAppContainer")))

export default function App() {
	const [screenPath, setScreenPath] = useState<string | null>(null)

	const switchOrg = (orgId: string) => {
		setScreenPath(null)
		setTimeout(() => {
			setScreenPath(`/${orgId}`)
		}, 600)
	}

	useEffect(() => {
		// DETERMINE SCREEN PATH FROM URL
		const getUrl = async () => {
			const urlFound = await Linking.getInitialURL()
			if (urlFound) {
				// for app published through Expo Go format is exp://exp.host/@learnink/learnink/--/path/into/app?hello=world
				if (urlFound.includes("exp://exp.host/@learnink/learnink")) {
					let screenPathFound = urlFound.replace("exp://exp.host/@learnink/learnink", "/")
					screenPathFound = screenPathFound.replace("/--/", "")
					setScreenPath(screenPathFound)
				}
				// for app running locally
				else if (urlFound.includes(":19000")) {
					let screenPathFound = urlFound.split(":19000").pop() || ""
					screenPathFound = screenPathFound.replace("/--/", "")
					setScreenPath(`/${screenPathFound}`)
				}
				// for production app format is learnink://path/into/app?hello=world
				else if (urlFound.includes("learnink://")) {
					const screenPathFound = urlFound.replace("learnink:/", "").replace("://scan/", "")
					setScreenPath(screenPathFound)
				}
				// for web version of app
				else {
					const screenPathFound = urlFound.substring(urlFound.indexOf("/", 8))
						? urlFound.substring(urlFound.indexOf("/", 8))
						: "/"
					setScreenPath(screenPathFound)
				}
			} else {
				setScreenPath("/")
			}
		}

		// eslint-disable-next-line no-void
		void getUrl()
	}, [])

	if (screenPath) {
		return (
			<PostHogProvider
				apiKey={Constants.expoConfig?.extra?.postHogApiKey}
				options={{
					defaultOptIn: false,
				}}
				autocapture={{
					captureLifecycleEvents: true,
					captureScreens: false,
					captureTouches: false,
				}}
			>
				<LoadingWrapper screenPath={screenPath}>
					<AAppContainer screenPath={screenPath} switchOrg={(orgId: string) => switchOrg(orgId)} />
				</LoadingWrapper>
			</PostHogProvider>
		)
	} else {
		return <></>
	}
}
