import { getAuth, signInWithCustomToken, signOut } from "firebase/auth"
import firebaseApp from "../../../firebase"

export const firebaseAuth = getAuth(firebaseApp)

export const firebaseSignInWithCustomToken = async (token) => {
	await signInWithCustomToken(firebaseAuth, token)
}

export const firebaseSignOut = async () => {
	await signOut(firebaseAuth)
}
