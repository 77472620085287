import { StyleSheet } from "react-native"

export const pageStyles = StyleSheet.create({
	fullWidth: {
		display: "flex",
		width: "100%",
		flex: 1,
	},
	fullScreen: {
		width: "100%",
		height: "100%",
	},
	flexColumnCentered: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignContent: "center",
		alignItems: "center",
	},
	flexColumnStart: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-start",
		alignContent: "center",
		alignItems: "center",
	},
	flexColumnEnd: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-end",
		alignContent: "center",
		alignItems: "center",
	},
	flexRowCentered: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignContent: "center",
		alignItems: "center",
	},
	flexRowStart: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
		alignContent: "center",
		alignItems: "center",
	},
	flexRowSpaceBetween: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignContent: "center",
		alignItems: "center",
	},
	footer: {
		width: "100%",
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		borderTopColor: "#EBECED",
		borderTopWidth: 1,
	},
	overlay: {
		position: "absolute",
		width: "100%",
		height: "100%",
		backgroundColor: "rgba(0,0,0,0.4)",
	},
	curlyText: {
		fontSize: 40,
		fontFamily: "Molle_400Regular_Italic",
		color: "white",
		width: "80%",
		textAlign: "center",
	},
	progressText: {
		color: "white",
		fontWeight: "bold",
		width: "80%",
		textAlign: "center",
		opacity: 0.8,
	},
})
