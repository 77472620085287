import React, { useContext } from "react"
import { StyleSheet, View, Text, TouchableOpacity } from "react-native"
import i18n from "i18n-js"
import PropTypes from "prop-types"
import FontAwesome from "@expo/vector-icons/FontAwesome"

import { OrgThemeContext } from "../../utils/context/Context"
import PulseWrapper from "../common/animations/PulseWrapper"
import LoadingSpinner from "../common/loading/LoadingSpinner"
import FlashingWrapper from "../common/animations/FlashingWrapper"

const defaultButtonPress = () => {
	return null
}

const styles = StyleSheet.create({
	overlay: {
		width: "100%",
		height: "100%",
		backgroundColor: "rgba(0,0,0,0.1)",
	},
	inner: {
		width: "100%",
		height: "87%",
		display: "flex",
		justifyContent: "center",
		alignContent: "center",
		alignItems: "center",
	},
	buttonText: {
		fontSize: 15,
		color: "white",
		fontWeight: "bold",
		width: "100%",
		textAlign: "center",
	},
})

const DefaultButton = ({
	backgroundColor,
	width,
	height,
	onPress,
	marginTop,
	marginBottom,
	fontSize,
	fontColor,
	outline,
	light,
	trackFBEvent,
	id,
	title,
	locked,
	disabled,
	animate,
	loading,
}) => {
	const themeData = useContext(OrgThemeContext)
	let formattedBackgroundColor = backgroundColor || "orange"
	if (backgroundColor === "primary" && themeData.primary) {
		formattedBackgroundColor = themeData.primary
	}
	if (backgroundColor === "secondary" && themeData.secondary) {
		formattedBackgroundColor = themeData.secondary
	}
	if (disabled) {
		formattedBackgroundColor = "#ebeef4"
	}
	const buttonText = title || i18n.t(id)
	const borderRadius = 8

	const handlePress = () => {
		if (onPress) {
			onPress()
		} else {
			defaultButtonPress()
		}
	}

	if (loading) {
		return (
			<View
				style={{
					width,
					height,
					backgroundColor: formattedBackgroundColor,
					borderRadius,
					marginBottom,
					marginTop,
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
					alignContent: "center",
					alignItems: "center",
				}}
			>
				<LoadingSpinner size="small" color="white" />
			</View>
		)
	}

	if (disabled) {
		return (
			<View
				style={{
					width,
					height,
					backgroundColor: formattedBackgroundColor,
					borderRadius,
					marginBottom,
					marginTop,
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
					alignContent: "center",
					alignItems: "center",
				}}
			>
				<Text
					style={[
						styles.buttonText,
						{
							fontSize,
							color: "grey",
						},
					]}
				>
					{buttonText}
				</Text>
			</View>
		)
	}

	if (locked) {
		return (
			<View
				style={{
					width,
					height,
					backgroundColor: "#dbedf5",
					borderRadius,
					marginBottom,
					marginTop,
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
					alignContent: "center",
					alignItems: "center",
				}}
			>
				<Text
					style={[
						styles.buttonText,
						{
							fontSize,
							color: "grey",
						},
					]}
				>
					<FontAwesome name="lock" size={fontSize * 1.2} color="grey" />
					<View style={{ width: 10 }} />
					{buttonText}
				</Text>
			</View>
		)
	}

	if (outline) {
		return (
			<View
				style={{
					width,
					height,
					backgroundColor: light ? "rgba(255,255,255,0.7)" : formattedBackgroundColor,
					borderRadius,
					marginBottom,
					marginTop,
				}}
			>
				<TouchableOpacity
					style={[
						styles.inner,
						{
							backgroundColor: "white",
							borderRadius,
							borderColor: light ? "rgba(255,255,255,0.7)" : formattedBackgroundColor,
							borderWidth: 1,
						},
					]}
					onPress={() => handlePress()}
				>
					<Text
						style={[
							styles.buttonText,
							{
								fontSize,
								color: formattedBackgroundColor,
							},
						]}
					>
						{buttonText}
					</Text>
				</TouchableOpacity>
			</View>
		)
	} else {
		return (
			<PulseWrapper style={{ width, height, marginBottom, marginTop }} animate={animate}>
				<View
					style={{
						backgroundColor: formattedBackgroundColor,
						borderRadius,
						width: "100%",
						height: "100%",
					}}
				>
					<View style={[styles.overlay, { borderRadius: borderRadius }]}>
						<FlashingWrapper style={{ width: "100%", height: "100%" }} animate={animate}>
							<TouchableOpacity
								style={[
									styles.inner,
									{
										backgroundColor: formattedBackgroundColor,
										borderRadius,
									},
								]}
								nativeID={trackFBEvent === true ? "button" : "default"}
								onPress={() => handlePress()}
							>
								<Text style={[styles.buttonText, { fontSize, color: fontColor || "white" }]}>
									{buttonText}
								</Text>
							</TouchableOpacity>
						</FlashingWrapper>
					</View>
				</View>
			</PulseWrapper>
		)
	}
}

DefaultButton.propTypes = {
	backgroundColor: PropTypes.string,
	width: PropTypes.any,
	height: PropTypes.number,
	onPress: PropTypes.func,
	marginBottom: PropTypes.number,
	marginTop: PropTypes.number,
	fontSize: PropTypes.number,
	fontColor: PropTypes.string,
	outline: PropTypes.bool,
	trackFBEvent: PropTypes.bool,
	id: PropTypes.string,
}

DefaultButton.defaultProps = {
	backgroundColor: "dodgerblue",
	width: "100%",
	height: 50,
	onPress: defaultButtonPress,
	marginBottom: 0,
	marginTop: 0,
	fontSize: 14,
	fontColor: "white",
	outline: false,
	trackFBEvent: false,
	id: "next",
}

export default DefaultButton
