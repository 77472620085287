// PUBLIC CERTIFICATE VIEW
// displays certificate pdf in an iframe for sharing on web

import React, { useEffect, useState } from "react"
import { Platform } from "react-native"
import PropTypes from "prop-types"
import Constants from "expo-constants"

import { CertificateScreenHelmet } from "../utils/reactHelmet/Helmets"
import { checkCertificateExists } from "../utils/generic/fetchData"
import { captureUserEvents } from "../utils/generic/EventLog"

import AppContainer from "../components/elements/AppContainer"
import LoadingSpinner from "../components/common/loading/LoadingSpinner"
import ErrorPage from "../components/elements/ErrorPage"

const CertificateScreen = ({ certificateId }) => {
	const [pageState, setPageState] = useState("loading")

	const checkCertificate = async () => {
		const certificateExists = await checkCertificateExists(certificateId)
		if (certificateExists) {
			setPageState("loaded")
		} else {
			setPageState("error")
		}
		const eventRow = {
			event: "public_certificate_view",
			properties: {
				certificate_id: certificateId,
			},
		}
		await captureUserEvents([eventRow])
	}

	// first check if pdf actually exists
	useEffect(() => {
		checkCertificate()
	}, [])

	if (pageState === "loading") {
		return <LoadingSpinner />
	}

	if (pageState === "error") {
		return (
			<AppContainer activeOrgData={{}}>
				<ErrorPage titleId="not_found" messageId="certificate_not_found" backgroundColor="rgba(0, 0, 0, 0.1)" />
			</AppContainer>
		)
	}

	// this page is designed only for sharing on web
	if (Platform.OS === "web") {
		return (
			<>
				<CertificateScreenHelmet />
				<AppContainer activeOrgData={{}}>
					<iframe
						width="100%"
						height="100%"
						src={`${Constants.expoConfig.extra.s3CertificateBucketUrl}${certificateId}.pdf`}
						title="Certificate"
					/>
				</AppContainer>
			</>
		)
	} else return null
}

CertificateScreen.propTypes = {
	certificateId: PropTypes.string.isRequired,
}

export default CertificateScreen
