import React, { useState } from "react"
import { View, Image } from "react-native"
import LoadingBar from "./LoadingBar"
import SpinningIcon from "./SpinningIcon"
import { pageStyles } from "../PageStyles"
// @ts-ignore
import Blue from "../../../assets/images/loading_icons/ink_blue.png"
// @ts-ignore
import Jade from "../../../assets/images/loading_icons/ink_jade.png"
// @ts-ignore
import Green from "../../../assets/images/loading_icons/ink_green.png"
// @ts-ignore
import Yellow from "../../../assets/images/loading_icons/ink_yellow.png"
// @ts-ignore
import Red from "../../../assets/images/loading_icons/ink_red.png"

interface Props {
	type: "LOADING_BAR" | "DEFAULT"
}

const SplashAnimation = ({ type }: Props) => {
	const [animationIndex, setAnimationIndex] = useState(0)
	const icons = [Blue, Jade, Green, Yellow, Red]

	return (
		<View style={[pageStyles.fullScreen, pageStyles.flexColumnCentered, { backgroundColor: "white" }]}>
			{type === "LOADING_BAR" && <LoadingBar startValue={100} endValue={180} />}
			{type === "DEFAULT" && (
				<>
					{animationIndex % 2 === 0 && (
						<SpinningIcon onAnimationEnd={() => setAnimationIndex(animationIndex + 1)}>
							<Image style={{ width: 54, height: 62 }} source={icons[animationIndex % icons.length]} />
						</SpinningIcon>
					)}
					{animationIndex % 2 === 1 && (
						<SpinningIcon onAnimationEnd={() => setAnimationIndex(animationIndex + 1)}>
							<Image style={{ width: 54, height: 62 }} source={icons[animationIndex % icons.length]} />
						</SpinningIcon>
					)}
				</>
			)}
		</View>
	)
}

export default SplashAnimation
