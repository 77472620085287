import Constants from "expo-constants"
import "react-native-get-random-values"
import AsyncStorage from "@react-native-async-storage/async-storage"
import { v4 as uuidv4 } from "uuid"

import { firebaseAuth } from "./firebaseAuthenticate"

const captureAuthedEvent = async (distinct_id, token, eventRows) => {
	try {
		const response = await fetch(`${Constants.expoConfig.extra.userApi}events/capture-authed`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				distinct_id,
				eventRows,
			}),
		})
		if (!response.ok) {
			const data = {
				unsyncedEvents: eventRows,
			}
			return data
		} else {
			const data = await response.json()
			return data
		}
	} catch (err) {
		const eventRowsSetOffline = eventRows.map((item) =>
			item.event !== undefined ? { ...item, properties: { ...item.properties, offline: true } } : item,
		)
		const data = {
			unsyncedEvents: eventRowsSetOffline,
		}
		return data
	}
}

const captureUnauthedEvent = async (distinct_id, eventRows) => {
	try {
		await fetch(`${Constants.expoConfig.extra.userApi}events/capture-unauthed`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				distinct_id,
				eventRows,
			}),
		})
	} catch (err) {
		console.log(err)
	}
}

export const captureUserEvents = async (events) => {
	// first remove any duplicate events
	const eventRows = [...new Set(events)]
	const user = firebaseAuth.currentUser
	if (user && user.uid) {
		const token = await user.getIdToken()
		const distinct_id = user.uid
		const data = await captureAuthedEvent(distinct_id, token, eventRows)
		return data
	} else {
		const storedId = await AsyncStorage.getItem("distinctId")
		if (storedId) {
			const distinct_id = storedId
			captureUnauthedEvent(distinct_id, eventRows)
		} else {
			const distinct_id = uuidv4()
			await AsyncStorage.setItem("distinctId", distinct_id)
			captureUnauthedEvent(distinct_id, eventRows)
		}
	}
}

const createAlias = async (user_id, alias, token) => {
	try {
		await fetch(`${Constants.expoConfig.extra.userApi}events/alias`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				alias,
				distinct_id: user_id,
			}),
		})
	} catch (err) {
		console.log(err)
	}
}

export const createUserAlias = async () => {
	try {
		const alias = await AsyncStorage.getItem("distinctId")
		const user = firebaseAuth.currentUser
		if (user && user.uid && alias && alias !== undefined) {
			const token = await user.getIdToken()
			createAlias(user.uid, alias, token)
		}
	} catch (err) {
		console.log(err)
	}
}
