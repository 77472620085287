import React from "react"
import * as Animatable from "react-native-animatable"

interface Props {
	onAnimationEnd: any
	children: any
}

const SpinningIcon = ({ onAnimationEnd, children }: Props) => {
	return (
		<Animatable.View
			animation="flipInY"
			direction="alternate"
			duration={2000}
			iterationCount={2}
			onAnimationEnd={onAnimationEnd}
		>
			{children}
		</Animatable.View>
	)
}

export default SpinningIcon
