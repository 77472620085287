import React from "react"
import { Platform } from "react-native"
import PropTypes from "prop-types"

import ReactHelmet from "./ReactHelmet"

export const CourseScreenHelmet = ({ info: { name, description, image } }) => {
	if (Platform.OS === "web") {
		return (
			<ReactHelmet
				data={{
					title: name,
					description,
					image,
				}}
			/>
		)
	} else return null
}

CourseScreenHelmet.propTypes = {
	info: PropTypes.shape({
		name: PropTypes.string,
		description: PropTypes.string,
		image: PropTypes.string,
	}),
}

CourseScreenHelmet.defaultProps = {
	info: {
		title: "Course",
		description: "Get started with the course today",
		image: "https://learnink-user-static.s3.eu-west-2.amazonaws.com/images/share_image.png",
		url: "",
	},
}

export const CertificateScreenHelmet = () => {
	if (Platform.OS === "web") {
		return (
			<ReactHelmet
				data={{
					title: `Course certificate`,
					description: `Check out my new course certificate!`,
					image: "https://learnink-user-static.s3.eu-west-2.amazonaws.com/images/certificate_share_img.png",
				}}
			/>
		)
	} else return null
}

export const CoursesScreenHelmet = ({ activeOrgInfo: { org_name, cover_image_url } }) => {
	if (Platform.OS === "web") {
		return (
			<ReactHelmet
				data={{
					title: `${org_name} courses`,
					description: `Check out the ${org_name} courses`,
					image: cover_image_url,
				}}
			/>
		)
	} else return null
}

CoursesScreenHelmet.propTypes = {
	activeOrgInfo: PropTypes.shape({
		org_name: PropTypes.string,
		cover_image_url: PropTypes.string,
	}),
}

CoursesScreenHelmet.defaultProps = {
	activeOrgInfo: {
		org_name: "My",
		cover_image_url: "https://learnink-user-static.s3.eu-west-2.amazonaws.com/images/share_image.png",
	},
}

export const OrgScreenHelmet = ({ activeOrgInfo: { org_name, cover_image_url } }) => {
	if (Platform.OS === "web") {
		return (
			<ReactHelmet
				data={{
					title: `Welcome to ${org_name}`,
					description: `Start learning with ${org_name}`,
					image: cover_image_url,
				}}
			/>
		)
	} else return null
}

OrgScreenHelmet.propTypes = {
	activeOrgInfo: PropTypes.shape({
		org_name: PropTypes.string,
		cover_image_url: PropTypes.string,
	}),
}

OrgScreenHelmet.defaultProps = {
	activeOrgInfo: {
		org_name: "My",
		cover_image_url: "https://learnink-user-static.s3.eu-west-2.amazonaws.com/images/share_image.png",
	},
}

export const ResourcesScreenHelmet = ({ activeOrgInfo: { org_name, cover_image_url } }) => {
	if (Platform.OS === "web") {
		return (
			<ReactHelmet
				data={{
					title: `${org_name} resources`,
					description: `Check out the ${org_name} resources`,
					image: cover_image_url,
				}}
			/>
		)
	} else return null
}

ResourcesScreenHelmet.propTypes = {
	activeOrgInfo: PropTypes.shape({
		org_name: PropTypes.string,
		cover_image_url: PropTypes.string,
	}),
}

ResourcesScreenHelmet.defaultProps = {
	activeOrgInfo: {
		org_name: "My",
		cover_image_url: "https://learnink-user-static.s3.eu-west-2.amazonaws.com/images/share_image.png",
	},
}

export const SignInScreenHelmet = ({ activeOrgInfo: { org_name, cover_image_url } }) => {
	if (Platform.OS === "web") {
		return (
			<ReactHelmet
				data={{
					title: `Register with ${org_name} to start learning`,
					description: `Register or sign in to your account to start learning with ${org_name}`,
					image: cover_image_url,
				}}
			/>
		)
	} else return null
}

SignInScreenHelmet.propTypes = {
	activeOrgInfo: PropTypes.shape({
		org_name: PropTypes.string,
		cover_image_url: PropTypes.string,
	}),
}

SignInScreenHelmet.defaultProps = {
	activeOrgInfo: {
		org_name: "My",
		cover_image_url: "https://learnink-user-static.s3.eu-west-2.amazonaws.com/images/share_image.png",
	},
}
