import React from "react"
import { View, StyleSheet, Image } from "react-native"
import PropTypes from "prop-types"
import OfflineEnabledImage from "../offline/OfflineEnabledImage"

const styles = StyleSheet.create({
	avatarContainer: {
		borderRadius: 99999,
		backgroundColor: "white",
		overflow: "hidden",
		margin: 0,
	},
})

function Avatar({ size, avatarData, avatarType, borderWidth, borderColor }) {
	const avatarContainer = {
		backgroundColor: avatarData ? avatarData.backgroundColor : "dodgerblue",
		width: borderWidth ? size + borderWidth * 2 : size,
		height: borderWidth ? size + borderWidth * 2 : size,
		borderWidth: borderWidth || 0,
		borderColor: borderColor || "#FFFFF",
	}

	if (avatarData.topType) {
		return (
			<View style={[styles.avatarContainer, avatarContainer]}>
				<OfflineEnabledImage
					style={{ width: size, height: size }}
					remoteImageUrl={`https://learnink-avatars.herokuapp.com/png/100?eyeType=${avatarData.eyeType}&topType=${avatarData.topType}&hairColor=${avatarData.hairColor}&mouthType=${avatarData.mouthType}&skinColor=${avatarData.skinColor}&clotheType=${avatarData.clotheType}&clotheColor=${avatarData.clotheColor}&eyebrowType=${avatarData.eyebrowType}&facialHairType=${avatarData.facialHairType}&accessoriesType=${avatarData.accessoriesType}&facialHairColor=${avatarData.facialHairColor}`}
				/>
			</View>
		)
	} else if (avatarType && avatarType === "teacher") {
		return (
			<View style={[styles.avatarContainer, avatarContainer, { backgroundColor: "#1bbc9c" }]}>
				<Image style={{ width: size, height: size }} source={require("./images/default_teacher.png")} />
			</View>
		)
	} else if (avatarType && avatarType === "learner") {
		return (
			<View style={[styles.avatarContainer, avatarContainer, { backgroundColor: "#ff8844" }]}>
				<Image style={{ width: size, height: size }} source={require("./images/default_learner.png")} />
			</View>
		)
	} else if (avatarType && avatarType === "sadFace") {
		return (
			<View style={[styles.avatarContainer, avatarContainer, { backgroundColor: "#1bbc9c" }]}>
				<Image style={{ width: size, height: size }} source={require("./images/avatar_sad.png")} />
			</View>
		)
	} else {
		return (
			<View style={[styles.avatarContainer, avatarContainer, { backgroundColor: "#1bbc9c" }]}>
				<Image style={{ width: size, height: size }} source={require("./images/avatar_happy.png")} />
			</View>
		)
	}
}

Avatar.propTypes = {
	size: PropTypes.number.isRequired,
	avatarData: PropTypes.shape({
		backgroundColor: PropTypes.string,
		topType: PropTypes.string,
		accessoriesType: PropTypes.string,
		hairColor: PropTypes.string,
		facialHairType: PropTypes.string,
		facialHairColor: PropTypes.string,
		clotheType: PropTypes.string,
		clotheColor: PropTypes.string,
		eyeType: PropTypes.string,
		eyebrowType: PropTypes.string,
		mouthType: PropTypes.string,
		skinColor: PropTypes.string,
	}),
	avatarType: PropTypes.string,
	borderWidth: PropTypes.number,
	borderColor: PropTypes.string,
}

Avatar.defaultProps = {
	avatarData: {},
	avatarType: null,
	borderWidth: 0,
	borderColor: "white",
}

export default Avatar
