import React from "react"
import {
	SaveOfflineContext,
	DeleteOfflineContext,
	CancelSaveOffline,
	ClearOfflineStatus,
} from "../../utils/context/Context"

const SaveOfflineWrapper = ({ children }) => {
	const saveOffline = () => {
		console.log("No save offline on web")
	}
	const deleteOffline = () => {
		console.log("No delete offline on web")
	}
	const cancelSaveOffline = () => {
		console.log("No cancel offline on web")
	}
	const clearOfflineStatus = () => {
		console.log("No clear offline on web")
	}
	return (
		<SaveOfflineContext.Provider value={saveOffline}>
			<DeleteOfflineContext.Provider value={deleteOffline}>
				<CancelSaveOffline.Provider value={cancelSaveOffline}>
					<ClearOfflineStatus.Provider value={clearOfflineStatus}>{children}</ClearOfflineStatus.Provider>
				</CancelSaveOffline.Provider>
			</DeleteOfflineContext.Provider>
		</SaveOfflineContext.Provider>
	)
}

export default SaveOfflineWrapper
