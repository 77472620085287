import React from "react"

export interface AudioStatus {
	id?: string
	status?: "playing" | "finished" | "loaded"
}

export interface AudioContextModel {
	audioStatus: AudioStatus
	handleLoadAudioFile: (id: string, audioFile: string) => void
	handlePlayAudioFile: (id: string, audioFile: string, nextSoundUri?: string | undefined) => void
	handlePlaySound: any
	isReadingAloud: (id: string | undefined) => boolean
}

export const ScaleContext = React.createContext(null)
export const OrgThemeContext = React.createContext(null)
export const LanguageContext = React.createContext(null)
export const SaveOfflineContext = React.createContext(null)
export const DeleteOfflineContext = React.createContext(null)
export const CancelSaveOffline = React.createContext(null)
export const ClearOfflineStatus = React.createContext(null)
export const AudioContext = React.createContext(null)
