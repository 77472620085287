import React, { useState } from "react"
import { Audio } from "expo-av"
import { AudioContext, AudioStatus } from "../../utils/context/Context"

// Initialize two audio objects for rotating use
const audioObject1 = new Audio.Sound()
const audioObject2 = new Audio.Sound()
let currentAudioObject = audioObject1
let nextAudioObject = audioObject2

// Track the URI of the currently loaded sound for each object
let loadedSoundUri1: string | undefined = undefined
let loadedSoundUri2: string | undefined = undefined

type DefaultSound = "achievement" | "complete" | "correct" | "incorrect" | "star" | "click"

const soundLibrary = {
	achievement: require("../../assets/sounds/achievement.mp3"),
	complete: require("../../assets/sounds/complete.mp3"),
	correct: require("../../assets/sounds/correct.mp3"),
	incorrect: require("../../assets/sounds/incorrect.mp3"),
	star: require("../../assets/sounds/star.m4a"),
	click: require("../../assets/sounds/click.mp3"),
}

export function AudioContainer({ children }: any) {
	const [audioStatus, setAudioStatus] = useState<AudioStatus>({})

	const handleLoadAudioFile = async (id: string, audioFile: string) => {
		currentAudioObject = audioObject1
		nextAudioObject = audioObject2
		await currentAudioObject.unloadAsync()
		await nextAudioObject.unloadAsync()
		await currentAudioObject.loadAsync({ uri: audioFile })
		loadedSoundUri1 = audioFile
		loadedSoundUri2 = undefined
		setAudioStatus({ id: id, status: "loaded" })
	}

	const handlePlayAudioFile = async (id: string, audioFile: string, nextSoundUri?: string | undefined) => {
		try {
			// If the file is not loaded in either, unload the current and load the new file
			try {
				await currentAudioObject.stopAsync()
				await nextAudioObject.stopAsync()
				setAudioStatus({})
			} catch (err) {}
			// Check if the audio file is already loaded in the 'current' audio object
			let isCurrentAudioLoaded =
				(currentAudioObject === audioObject1 && loadedSoundUri1 === audioFile) ||
				(currentAudioObject === audioObject2 && loadedSoundUri2 === audioFile)
			// If not already loaded in the current, check if it's loaded in the 'next' and swap if so
			if (!isCurrentAudioLoaded) {
				let isNextAudioLoaded =
					(nextAudioObject === audioObject1 && loadedSoundUri1 === audioFile) ||
					(nextAudioObject === audioObject2 && loadedSoundUri2 === audioFile)
				if (isNextAudioLoaded) {
					// Swap the audio objects if the next audio object has the audio file loaded
					;[currentAudioObject, nextAudioObject] = [nextAudioObject, currentAudioObject]
				} else {
					await currentAudioObject.unloadAsync()
					await currentAudioObject.loadAsync({ uri: audioFile })

					// Update the loaded sound URI for the current audio object
					if (currentAudioObject === audioObject1) {
						loadedSoundUri1 = audioFile
					} else {
						loadedSoundUri2 = audioFile
					}
				}
			}

			// Play the current audio file
			await currentAudioObject.playAsync()
			setAudioStatus({ id: id, status: "playing" })

			// Preload the next sound in the 'next' audio object if provided
			if (
				nextSoundUri &&
				((nextAudioObject === audioObject1 && loadedSoundUri1 !== nextSoundUri) ||
					(nextAudioObject === audioObject2 && loadedSoundUri2 !== nextSoundUri))
			) {
				await nextAudioObject.unloadAsync() // Ensure to unload the next object before loading new content
				await nextAudioObject.loadAsync({ uri: nextSoundUri })

				// Update the loaded sound URI for the next audio object
				if (nextAudioObject === audioObject1) {
					loadedSoundUri1 = nextSoundUri
				} else {
					loadedSoundUri2 = nextSoundUri
				}
			}
		} catch (error) {
			console.log("Error in handlePlayAudioFile", error)
		}
	}

	const handlePlaySound = async (id: DefaultSound) => {
		try {
			try {
				await currentAudioObject.stopAsync()
				setAudioStatus({})
			} catch (err) {}
			try {
				await currentAudioObject.unloadAsync()
			} catch (err) {}
			await currentAudioObject.loadAsync(soundLibrary[id])
			loadedSoundUri1 = id
			await currentAudioObject.playAsync()
			setAudioStatus({ id: id, status: "playing" })
		} catch (error) {
			console.log(error)
		}
	}

	const isReadingAloud = (id: string | undefined): boolean => {
		if (id && audioStatus.id === id && audioStatus.status === "playing") {
			return true
		}
		return false
	}

	currentAudioObject.setOnPlaybackStatusUpdate((playbackStatus) => {
		// @ts-ignore
		if (playbackStatus.didJustFinish) {
			setAudioStatus({ ...audioStatus, status: "finished" })
		}
	})

	return (
		<AudioContext.Provider
			// @ts-ignore
			value={{ audioStatus, handleLoadAudioFile, handlePlayAudioFile, handlePlaySound, isReadingAloud }}
		>
			{children}
		</AudioContext.Provider>
	)
}

export default AudioContainer
