import React, { useRef, useEffect } from "react"
import { Animated, StyleSheet, View } from "react-native"

const styles = StyleSheet.create({
	container: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
	},
	progress: {
		backgroundColor: "rgba(0, 0, 0, 0.1)",
		justifyContent: "flex-start",
		borderRadius: 100,
		alignItems: "center",
		position: "relative",
		paddingVertical: 0,
		paddingHorizontal: 5,
		display: "flex",
		height: 20,
		width: 200,
		flexDirection: "row",
	},
	progressValue: {
		borderRadius: 100,
		backgroundColor: "dodgerblue",
		height: 14,
	},
})

interface Props {
	startValue: number
	endValue: number
}

const LoadingBar = ({ startValue, endValue }: Props) => {
	const barAnim = useRef(new Animated.Value(startValue)).current // Initial value for opacity: 0

	useEffect(() => {
		Animated.timing(barAnim, {
			toValue: endValue,
			duration: 1500,
			useNativeDriver: false,
		}).start()
	}, [barAnim, endValue])

	return (
		<View style={styles.container}>
			<View style={styles.progress}>
				<Animated.View style={[styles.progressValue, { width: barAnim }]} />
			</View>
		</View>
	)
}

export default LoadingBar
