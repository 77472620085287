import React from "react"
import { StyleSheet, View, ActivityIndicator } from "react-native"
import PropTypes from "prop-types"

const styles = StyleSheet.create({
	container: {
		width: "100%",
		height: "100%",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		alignContent: "center",
	},
})

const LoadingSpinner = ({ color, size }) => {
	return (
		<View style={styles.container}>
			<ActivityIndicator size={size} color={color} />
		</View>
	)
}

LoadingSpinner.propTypes = {
	color: PropTypes.string,
	size: PropTypes.string,
}

LoadingSpinner.defaultProps = {
	color: "dodgerblue",
	size: "large",
}

export default LoadingSpinner
