import React from "react"
import { StyleSheet, View, ImageBackground } from "react-native"
import PropTypes from "prop-types"

import CourseBackgroundImg from "../../assets/images/background_cloud_overlay.png"
import ErrorCard from "./ErrorCard"

const styles = StyleSheet.create({
	container: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
		position: "absolute",
		width: "100%",
		height: "100%",
		zIndex: 9999999,
	},
	imageOverlay: {
		width: "100%",
		resizeMode: "cover",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignContent: "center",
		alignItems: "center",
		padding: "10%",
	},
})

const ErrorPage = ({ title, titleId, messageId, message, onButtonPress, buttonId, backgroundColor }) => {
	return (
		<View
			style={[
				styles.container,
				{
					backgroundColor: backgroundColor || "#e3e7eb",
				},
			]}
		>
			<ImageBackground source={CourseBackgroundImg} style={[styles.imageOverlay, { height: "100%" }]}>
				<ErrorCard
					title={title}
					titleId={titleId}
					message={message}
					messageId={messageId}
					onButtonPress={onButtonPress}
					buttonId={buttonId}
					backgroundColor="rgba(0,0,0,0)"
				/>
			</ImageBackground>
		</View>
	)
}

ErrorPage.propTypes = {
	title: PropTypes.string,
	titleId: PropTypes.string,
	messageId: PropTypes.string,
	message: PropTypes.string,
	onButtonPress: PropTypes.func,
	buttonId: PropTypes.string,
	backgroundColor: PropTypes.string,
}

ErrorPage.defaultProps = {
	title: "",
	titleId: "",
	messageId: "",
	message: "",
	onButtonPress: null,
	buttonId: "",
	backgroundColor: "",
}

export default ErrorPage
