import { FontAwesome } from "@expo/vector-icons"
import React, { useState } from "react"
import { Image, ImageBackground, View } from "react-native"

interface Props {
	remoteImageUrl: string
	style?: any
	resizeMode?: "cover" | "contain" | "stretch" | "repeat" | "center"
	isBackground?: boolean
	errorComponent?: any
}

const OfflineEnabledImage = ({ remoteImageUrl, style, resizeMode, isBackground, errorComponent }: Props) => {
	const [imageState, setImageState] = useState<"loaded" | "error" | undefined>(undefined)

	if (imageState === "error") {
		if (errorComponent) {
			return errorComponent
		}
		return (
			<View style={[style, { backgroundColor: "white" }]}>
				<FontAwesome name="image" size={24} color="grey" />
			</View>
		)
	}

	if (isBackground) {
		return (
			<ImageBackground
				style={style}
				source={{
					uri: remoteImageUrl,
				}}
				resizeMode={resizeMode || "cover"}
				onLoad={() => setImageState("loaded")}
				onError={() => setImageState("error")}
			/>
		)
	}

	return (
		<Image
			style={style}
			source={{
				uri: remoteImageUrl,
			}}
			resizeMode={resizeMode || "cover"}
			onLoad={() => setImageState("loaded")}
			onError={() => setImageState("error")}
		/>
	)
}

export default OfflineEnabledImage
