import React from "react"
import { StyleSheet, View, Text } from "react-native"
import PropTypes from "prop-types"

import DefaultButton from "./DefaultButton"
import TranslatableText from "./TranslatableText"

import Avatar from "./Avatar"

const styles = StyleSheet.create({
	container: {
		width: "100%",
		height: "100%",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		alignContent: "center",
	},
	errorCard: {
		width: "80%",
		backgroundColor: "white",
		maxWidth: 500,
		borderRadius: 8,
		padding: 24,
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		alignContent: "center",
		justifyContent: "center",
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 1,
		},
		shadowOpacity: 0.22,
		shadowRadius: 2.22,
		elevation: 3,
	},
	errorTitle: {
		textAlign: "center",
		fontSize: 18,
		fontWeight: "bold",
		paddingBottom: 5,
		minWidth: "100%",
		marginTop: 10,
	},
	errorText: {
		textAlign: "center",
		fontSize: 14,
		lineHeight: 20,
	},
})

const ErrorCard = ({
	title,
	titleId,
	messageId,
	message,
	onButtonPress,
	buttonId,
	backgroundColor,
	centred,
	avatarType,
}) => {
	return (
		<View
			style={[
				styles.container,
				{
					backgroundColor,
					justifyContent: centred === true ? "center" : "flex-start",
					paddingTop: centred === true ? 0 : "16%",
				},
			]}
		>
			<View style={styles.errorCard}>
				<Avatar avatarType={avatarType || "sadFace"} size={60} />
				{title && <Text style={styles.errorTitle}>{title}</Text>}
				{titleId && (
					<TranslatableText
						style={{
							textAlign: "center",
							fontSize: 18,
							fontWeight: "bold",
							paddingBottom: 5,
							minWidth: "100%",
							marginTop: 10,
						}}
						id={titleId}
						textAlign="center"
					/>
				)}
				{messageId && (
					<TranslatableText
						style={{
							textAlign: "center",
							fontSize: 14,
							lineHeight: 20,
						}}
						id={messageId}
						textAlign="center"
					/>
				)}
				{message && <Text style={styles.errorText}>{message}</Text>}
				{onButtonPress && buttonId && (
					<View style={{ marginTop: 18, width: "100%" }}>
						<DefaultButton
							id={buttonId}
							backgroundColor="dodgerblue"
							width="100%"
							height={44}
							onPress={() => onButtonPress()}
							fontSize={14}
							outline={false}
						/>
					</View>
				)}
			</View>
		</View>
	)
}

ErrorCard.propTypes = {
	title: PropTypes.string,
	titleId: PropTypes.string,
	messageId: PropTypes.string,
	message: PropTypes.string,
	onButtonPress: PropTypes.func,
	buttonId: PropTypes.string,
	backgroundColor: PropTypes.string,
	centred: PropTypes.bool,
}

ErrorCard.defaultProps = {
	title: null,
	titleId: null,
	messageId: null,
	message: null,
	onButtonPress: null,
	buttonId: null,
	backgroundColor: "rgba(0, 0, 0, 0)",
	centred: false,
}

export default ErrorCard
