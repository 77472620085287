export const validateCourseContent = (courseContent) => {
	if (
		courseContent &&
		courseContent.stages &&
		courseContent.modules &&
		courseContent.module_stages &&
		courseContent.lesson_cards &&
		courseContent.question_cards &&
		courseContent.publish_options
	) {
		if (
			Array.isArray(courseContent.stages) &&
			courseContent.stages.length > 0 &&
			Array.isArray(courseContent.modules) &&
			courseContent.modules.length > 0 &&
			Array.isArray(courseContent.module_stages) &&
			courseContent.module_stages.length > 0 &&
			Array.isArray(courseContent.lesson_cards) &&
			courseContent.lesson_cards.length > 0 &&
			Array.isArray(courseContent.question_cards) &&
			courseContent.question_cards.length > 4 &&
			typeof courseContent.publish_options.useBaseline === "boolean"
		) {
			return true
		} else {
			return false
		}
	} else return false
}

export const validateUnauthedUserData = (unauthedData) => {
	if (unauthedData) {
		if (unauthedData.orgNotFound) {
			return true
		} else {
			if (
				unauthedData.activeOrgData &&
				unauthedData.activeOrgData.org_id &&
				unauthedData.activeOrgData.info &&
				unauthedData.activeOrgData.info.theme &&
				unauthedData.activeOrgData.info.org_name &&
				unauthedData.activeOrgData.info.logo_image_url &&
				unauthedData.activeOrgData.info.cover_image_url
			) {
				return true
			} else return false
		}
	} else {
		return false
	}
}
