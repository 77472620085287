import React from "react"
import Helmet from "react-helmet"
import Constants from "expo-constants"
import PropTypes from "prop-types"

const ReactHelmet = ({ data: { title, description, image, url } }) => {
	return (
		<Helmet>
			{/* <!-- HTML Meta Tags --> */}
			<title>{title}</title>
			<meta name="description" content={description} />

			{/* <!-- Google / Search Engine Tags --> */}
			<meta itemProp="name" content={title} />
			<meta itemProp="description" content={description} />
			<meta itemProp="image" content={image} />

			{/* <!-- Facebook Meta Tags --> */}
			<meta property="og:url" content={Constants.expoConfig.extra.shareUrl + url} />
			<meta property="og:type" content="website" />
			<meta property="og:title" content={title} />
			<meta property="og:description" content={description} />
			<meta property="og:image" content={image} />

			{/* <!-- Twitter Meta Tags --> */}
			<meta name="twitter:card" content="summary" />
			<meta name="twitter:title" content={title} />
			<meta name="twitter:description" content={description} />
			<meta name="twitter:image" content={image} />
		</Helmet>
	)
}

ReactHelmet.propTypes = {
	data: PropTypes.shape({
		title: PropTypes.string,
		description: PropTypes.string,
		image: PropTypes.string,
		url: PropTypes.string,
	}),
}

ReactHelmet.defaultProps = {
	data: {
		title: "Learn.ink",
		description: "Welcome to Learn.ink",
		image: "https://learnink-user-static.s3.eu-west-2.amazonaws.com/images/share_image.png",
		url: "",
	},
}

export default ReactHelmet
