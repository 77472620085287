import React, { useState, useEffect } from "react"
import { Platform, Dimensions, Modal } from "react-native"
import * as Linking from "expo-linking"
import Constants from "expo-constants"
import loadable from "@loadable/component"
import PropTypes from "prop-types"

import loaderRetry from "./loaderRetry"

import SplashAnimation from "../../components/common/loading/SplashAnimation"
import ErrorPage from "../../components/elements/ErrorPage"
import CertificateScreen from "../../screens/CertificateScreen"

const DemoScreen = loadable(() => loaderRetry(() => import("../../screens/DemoScreen")))

export const UpdateLoadingContext = React.createContext(null)

const LoadingWrapper = ({ screenPath, children }) => {
	const [showLoadingScreen, setShowLoadingScreen] = useState(true)
	const [shortLinkDetected, setShortLinkDetected] = useState(null)
	const [demoScreenDetected, setDemoScreenDetected] = useState(null)
	const [certificateDetected, setCertificateDetected] = useState(null)
	const [redirectUrl, setRedirectUrl] = useState(null)

	const window = Dimensions.get("window")
	const desktopView = !!(Platform.OS === "web" && window.width > 500) // check whether the app is in mobile or desktop view

	const fetchShortLinkResponse = async (shortLinkKey) => {
		try {
			const response = await fetch(`${Constants.expoConfig.extra.userApi}short-url/${shortLinkKey}`, {
				method: "GET",
			})
			if (!response.ok) {
				setRedirectUrl("not_found")
			} else {
				const redirect = await response.text()
				setRedirectUrl(redirect)
				await Linking.openURL(redirect)
			}
		} catch (err) {
			setRedirectUrl("not_found")
		}
	}

	useEffect(() => {
		const urlPartOne = screenPath.split("/", 2)[1]
		if (urlPartOne === "s") {
			const shortLinkKey = screenPath.split("/", 3)[2]
			setShortLinkDetected(true)
			fetchShortLinkResponse(shortLinkKey)
		} else if (urlPartOne === "certificate") {
			setCertificateDetected(true)
		} else if (urlPartOne === "demo") {
			setDemoScreenDetected(true)
		} else {
			setShortLinkDetected(false)
		}
	}, [desktopView, screenPath])

	const updateLoadingContext = (value) => {
		setShowLoadingScreen(value)
	}

	if (shortLinkDetected && redirectUrl === null) {
		return <ErrorPage message="Searching for page..." />
	}

	if (certificateDetected) {
		return <CertificateScreen certificateId={screenPath.split("/", 3)[2]} />
	}

	if (demoScreenDetected) {
		return <DemoScreen />
	}

	if (shortLinkDetected && redirectUrl === "not_found") {
		return (
			<ErrorPage
				title="Not found"
				message="Sorry we can't seem to find this page. It looks like you've pressed on a broken link..."
			/>
		)
	}

	if (shortLinkDetected && redirectUrl !== null) {
		return <ErrorPage message="Page found 🙂" />
	}

	return (
		<UpdateLoadingContext.Provider value={updateLoadingContext}>
			{children}
			{showLoadingScreen && (
				<Modal>
					<SplashAnimation type={desktopView ? "LOADING_BAR" : "DEFAULT"} />
				</Modal>
			)}
		</UpdateLoadingContext.Provider>
	)
}

export default LoadingWrapper

LoadingWrapper.propTypes = {
	screenPath: PropTypes.string,
	children: PropTypes.any.isRequired,
}

LoadingWrapper.defaultProps = {
	screenPath: "/",
}
